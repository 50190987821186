import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "pages/Home";
import Contact from "pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      {/* <ScrollToTop smooth color="#246BFD" /> */}
    </BrowserRouter>
  );
}

export default App;
