import React from "react";
import {
  FaTwitter,
  FaYoutube,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaChevronRight,
  // FaMapMarkedAlt,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/logo2.png" alt="img" />
                </div>
                <div className="details">
                  <p>
                    la CAN Coop vous invite à nous rejoindre. Ensemble, nous
                    pouvons bâtir un avenir où les arts numériques sont
                    accessibles, valorisés, et en constante évolution.
                  </p>
                  <div className="subscribe mt-4">
                    <input type="text" placeholder="E-mail" />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6"></div>
            <div className="col-lg-4 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contactez-nous</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    {/* <li>
                      <FaMapMarkedAlt />
                      4517 Washington Ave. Manchester, Kentucky 39495
                    </li> */}
                    <li className="mt-3">
                      <FaPhoneAlt /> (+888) 123 456 765
                    </li>
                    <li className="mt-2">
                      <FaEnvelope /> infoname@mail.com
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a href="">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© cancoop.net 2024 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                {/* <a href="#">Trams &amp; Condition</a> */}
                {/* <a href="#">Privacy Policy</a> */}
                {/* <a href="#">Contact Us</a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default Footer;
