import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      {/* ================== Banner Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 pb-0 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pb-xl-5 align-self-center">
              <div className="banner-inner pe-xl-4 pb-5">
                <h6
                  className="bg-base-2 text-white subtitle"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  Bienvenue à la Coopérative des Arts Numériques
                </h6>
                <h2
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  <span>CAN Coop</span>
                </h2>
                <p
                  className="content pe-xl-4"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  À propos de nous La CAN Coop est une coopérative dédiée à la
                  promotion et au développement des cultures et des pratiques
                  artistiques numériques. Fondée sur les principes de
                  collaboration et d'innovation, notre mission est de soutenir
                  et d'encourager la création numérique sous toutes ses formes,
                  à l'échelle nationale et internationale, en particulier en
                  Afrique.
                </p>
                <p
                  className="content pe-xl-4"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  Nos Objectifs à la CAN Coop, nous croyons fermement que l'art
                  numérique est un vecteur puissant de transformation culturelle
                  et sociale. Notre objectif principal est de faciliter la
                  réflexion, la production, et la diffusion des œuvres
                  numériques tout en offrant une plateforme pour la réception
                  critique de ces œuvres. Nous visons à faire progresser la
                  reconnaissance et l'appréciation des arts numériques en
                  Afrique et au-delà.
                </p>
                <Link
                  className="btn btn-border-base-2"
                  data-aos="fade-right"
                  data-aos-delay="450"
                  data-aos-duration="1500"
                  to="/contact"
                >
                  Contact <FaPlus />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 align-self-end">
              <div className="banner-thumb-2 mt-4 mt-lg-0">
                <div className="main-img-wrap">
                  <img
                    className="banner-animate-img banner-animate-img-1 left_image_bounce"
                    src="assets/img/banner-5/4.png"
                    alt="img"
                  />
                  <img
                    className="banner-animate-img banner-animate-img-3 top_image_bounce"
                    src="assets/img/banner-5/3.png"
                    alt="img"
                  />
                  <div>
                    <img
                      className="main-img"
                      src="assets/img/banner/2.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== Banner End ==================*/}
    </>
  );
};

export default Banner;
