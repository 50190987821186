import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Banner from "components/Banner";
import CaseStudyArea from "components/CaseStudyArea";

const Home = () => {
  return (
    <>
      {/* Navigation Bar */}
      <Navbar />

      {/* Banner Five */}
      <Banner />

      {/* Case Study Area Two */}
      <CaseStudyArea />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Home;
