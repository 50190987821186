import React from "react";

const CaseStudyArea = () => {
  return (
    <>
      {/*================== case-study area start ==================*/}
      <div className="case-study-area pd-top-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="section-title text-center">
                <h6 className="sub-title-sky-blue">Rejoignez-nous</h6>
                <h2 className="title">
                  Que vous soyez un artiste, ou
                  simplement curieux
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="single-case-study-inner style-2">
                <div className="thumb">
                  <img src="assets/img/case-study/vr.jpg" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <div>Virtual reality</div>
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-case-study-inner style-2">
                    <div className="thumb">
                      <img src="assets/img/case-study/ar.jpg" alt="img" />
                    </div>
                    <div className="details">
                      <h5>
                        <div>Augmented reality</div>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-case-study-inner style-2">
                    <div className="thumb">
                      <img src="assets/img/case-study/inex.jpg" alt="img" />
                    </div>
                    <div className="details">
                      <h5>
                        <div>Inmersive Exhibition</div>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-case-study-inner style-2">
                <div className="thumb">
                  <img src="assets/img/case-study/robotic.jpg" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <div>Robotic</div>
                  </h5>
                </div>
              </div>
              <div className="single-case-study-inner style-2">
                <div className="thumb">
                  <img src="assets/img/case-study/dancess.jpg" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <div>Digital Dance</div>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyArea;
