import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";
import ContactMain from "components/ContactMain";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar */}
      <Navbar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Main */}
      <ContactMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Contact;
